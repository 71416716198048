import React from 'react';
import HeroSlider from '../components/HeroSlider';
import ServicesHome from '../components/ServicesHome';
import ClientsHome from '../components/ClientsHome';
import TopMachinesHome from '../components/TopMachinesHome';
import PartnersHome from '../components/PartnersHome';
import Testimonials from '../components/Testimonials';
import ContactHome from '../components/ContactHome';
import CeoMessage from '../components/CeoMessage';
import { Helmet } from 'react-helmet-async';

const Home = () => {
  return (
    <>

<Helmet>
  <title>Pharmachinery</title>
  <meta name="description" content="Pharmachinery group provides integrated pharmaceutical and engineering solutions in Egypt and MENA region. Pharmachinery is your premier pharmaceutical machinery and Cleanroom solutions provider." />
</Helmet>
      <HeroSlider />
      <ServicesHome />
      <ClientsHome />
      <TopMachinesHome />
      <PartnersHome />
      <Testimonials />
      <CeoMessage bg="#f5f5f5" />
      <ContactHome /> 
    </>
  )
}

export default Home