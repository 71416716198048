import React from 'react';
import projectsBannerDesktop from '../assets/projects-banner-desktop.png';
import PageHead from '../components/PageHead';
import ProjectSection from '../components/ProjectSection';
import kpi from '../assets/kpipharma.png';
import medcare from '../assets/medcare.png';
import alexandria from '../assets/alexandria.png';
import rafada from '../assets/rafada.png';
import sabaa from '../assets/sabaa.png';
import eipico from '../assets/eipico.jpg';
import globalpharma from '../assets/globalpharma.png';
import pharco from '../assets/pharco.png';
import macro from '../assets/macro.png';
import ubm from '../assets/ubm.png';
import hikma from '../assets/hikma.png';
import beta from "../assets/beta.png";
import pharmapack from '../assets/pharmapack.png';
import saco from '../assets/saco.png';



import { Helmet } from 'react-helmet-async';
import { v4 as uuidv4 } from 'uuid';
const Projects = () => {
  const projectsList = [
    {
    id:uuidv4(),
    title:'Karman Pharmaceutical Industries',
    reverse:false,
    workDone:"General Consultation,Clean room system,Tablet press machine,Sachet filling machine,Eyedrop machine,MDI Machine,Licaps Machine,Complete automatic counting line,Water system",
    image:kpi
    },
    {
    id:uuidv4(),
    title:'Med Care',
    reverse:true,
    workDone:"General Consultation,Clean room system,Tablet press machine,Sachet filling machine,Eyedrop machine,MDI Machine,Licaps Machine,Complete automatic counting line,Water system",
    image:medcare
    },
    {
    id:uuidv4(),
    title:'Eipico',
    reverse:false,
    workDone:"Clean room system",
    image:eipico
    },
    {
    id:uuidv4(),
    title:'Global Pharma',
    reverse:true,
    workDone:"Clean room system",
    image:globalpharma
    },
    {
    id:uuidv4(),
    title:'Pharco',
    reverse:false,
    workDone:"Clean room system",
    image:pharco
    },
    {
    id:uuidv4(),
    title:'Macro',
    reverse:true,
    workDone:"Cartooning machine",
    image:macro
    },
    {
    id:uuidv4(),
    title:'Rafada 2Rs',
    reverse:false,
    workDone:"General Consultation,Clean room system,ODF production line,Semisolid Line,BOV machine,Tube filling machine,Liquid Line,Unidose machine",
    image:rafada
    },
    {
    id:uuidv4(),
    title:'Hikma Egypt',
    reverse:true,
    workDone:"Vial washining machine",
    image:hikma
    },
    {
    id:uuidv4(),
    title:'Saco Medical, Saudi Arabia',
    reverse:false,
    workDone:"General Consulation,ODF Line,Tablet Press,Sachet machine,Unidose,Blister machine,Licaps,Double Alu Packing machine,Candy line,Counting line",
    image:saco
    },
    {
    id:uuidv4(),
    title:'Sabaa International Pharma',
    reverse:true,
    workDone:"General Consulation",
    image:sabaa
    },
    {
    id:uuidv4(),
    title:'Alexandria Pharmacueticals',
    reverse:false,
    workDone:"ODF Line",
    image:alexandria
    },
    {
    id:uuidv4(),
    title:'United Biomed',
    reverse:true,
    workDone:"Liquid Line,Semisolid Line,Tube Filling Machine,Inspection Machine",
    image:ubm
    },
    {
    id:uuidv4(),
    title:'Beta Pharma',
    reverse:false,
    workDone:"Cleanroom System",
    image:beta
    },
    {
    id:uuidv4(),
    title:"Pharma Pack",
    reverse:true,
    workDone:"General Consultation,Cleanroom System,ODF Line,Liquid Line,Semisolid System,Tube Filling,Water System",
    image:pharmapack
    },
  ]
  return (
    <div className="projects">
        <Helmet>
          <title>Pharmachinery Projects</title>
          <meta name="description" content="Pharmachinery group provides integrated pharmaceutical and engineering solutions in Egypt and MENA region. Pharmachinery is your premier pharmaceutical machinery and Cleanroom solutions provider." />
        </Helmet>
      <PageHead img={projectsBannerDesktop} />
      <div className="section-head-info flex flex-col items-center justify-center text-center p-3 py-8">
            <p className='text-red-600 font-bold py-1'>Our Projects</p>
            <h1 className='text-2xl	font-bold dark py-1'>we are delivering only exceptional high quality projects</h1>
      </div>
      {projectsList.map((project) => (
              <ProjectSection key={project.id} reverse={project.reverse} img={project.image} title={project.title} description={project.workDone} />
      ))}
    
    </div>
    
  )
}

export default Projects;