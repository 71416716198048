import React from 'react';
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination,  Autoplay } from "swiper/core";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from 'uuid';
const Machine = ({white , title , description , images , id , features}) => {

    SwiperCore.use([Pagination, Autoplay]);

  return (
    <section id={id} className={`bg-white lg:p-24`}>
       <div className="container mx-auto">
       <div className='lg:flex lg:items-center lg:justify-center'>
        <div className="slider w-full lg:w-[50%] lg:order-2 ">
            <Swiper
                spaceBetween={50}
                slidesPerView={1}
                pagination
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                className="w-[100%]"
            >
          
                {images.map((image) => (
                        <SwiperSlide key={uuidv4()}>
                            <img src={image} alt="machineImage" className='w-full' />
                        </SwiperSlide>
                ))}
            </Swiper>
        </div>

        <div className="machine-info p-4 lg:order-1">
            <h1 className='font-bold text-3xl py-2'>{title}</h1>
            <p className='py-1'>{description}</p>
            <button
            type="button"
            className="inline-block w-[224px] my-4 bg-red-600 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
                <Link to="/contact">Request Technical Data</Link>
          </button> 
        </div>

        </div>
        <div className="features p-4">
            <h1 className='font-semibold text-xl py-2'>Performance & Features</h1>
          <div className='lg:flex lg:justify-between'>
          <div className='lg:w-[45%]'>
            
               {features.map((feature , index) => { 
                if(index <= 3){
                    return (<div className="feature py-2 flex" key={uuidv4()}>
                    <FontAwesomeIcon className='text-white p-1 bg-red-600 rounded-full w-[20px] h-[20px]' icon={faCheck} />
                    <p className='pl-3'>{feature}</p>
                    </div>)
                }
                })}
           </div>
           <div className="lg:w-[45%]">
                {
                    features.map((feature , index) => {
                        if(index >= 3){
                            return <div className="feature py-2 flex" key={uuidv4()}>
                            <FontAwesomeIcon className='text-white p-1 bg-red-600 rounded-full w-[20px] h-[20px]' icon={faCheck} />
                            <p className='pl-3'>{feature}</p>
                            </div>
                        }
                    })
                }
           </div>
          </div>
        </div>
       </div>
    </section>
  )
}

export default Machine;