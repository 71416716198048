import React  from "react";
import TurnkeyService from "../assets/TurnkeyService.png";
import consulations from "../assets/consultations.png";
import machinery from "../assets/machinery.png";
import ServiceCard from "./ServiceCard";
import { useInView } from "react-intersection-observer";

const ServicesHome = () => {
  const { ref: myRef, inView: myElementIsVisible } = useInView();
  
  return (
    <div ref={myRef} className="services-section px-2 container mx-auto">
      <div className="section-head-info flex flex-col items-center justify-center text-center p-8">
        <p className="text-red-600 font-bold py-1">Our Services</p>
        <h1 className="text-2xl	font-bold dark py-1">What We Do?</h1>
        <p className="lg:w-[50%] py-1">
        We are a complete solution for establishing of pharmaceutical, nutraceutical and cosmetics factories

        </p>
      </div>
      <div className={`services-cards ${myElementIsVisible ? `animate__animated animate__fadeInDown` : ``} p-4 flex flex-col justify-around items-center lg:flex-row lg:flex lg:justify-around`}>
        <ServiceCard
          to='/services#turnkey'
          img={TurnkeyService}
          title="Turnkey"
          description="Pharmachinery provides key services to align with effective GMP framework and pharmaceutical project management."
        />
        <ServiceCard
          to='/services#consulations'
          img={consulations}
          title="Pharmacutical & Engineering Consultaions"
          description="A. Fesability study , B. Cost estimation , ..."
        />
        <ServiceCard
          to='/services#machinery'
          img={machinery}
          title="Pharmacutical Machinery"
          description="Lorem ipsum dolor sit amet consectetur. Diam id bibendum et imperdiet
          bibendum ipsum"
        />
      </div>
    </div>
  );
};

export default ServicesHome;
