import React from "react";
import "swiper/css";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Autoplay } from "swiper/core";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Testimonial from "./Testimonial";
import { v4 as uuidv4 } from "uuid";



const testimonialsList = [
  {
    id: uuidv4(),
    name: "Dr Wasim Garman",
    review: "Pharmachinary has proven to be an exceptional A-Z company, providing perfect services and innovative ideas that have significantly contributed to our company's progression. Their expertise in optimizing production facilities has given us a remarkable competitive edge, allowing us to gain more market share and boost profits. We view Pharmachinary as a valuable innovation partner, helping us turn our dreams into reality.",
    position: "Chairman of Rafada 2Rs"
  },
  {
    id: uuidv4(),
    name: "Dr Mohamed Marrawy",
    review: "Our partnership with Pharmachinary has been a game-changer for our pharmaceutical business. Their innovative solutions in production facilities have been instrumental in achieving our main objective of creating innovative products that stand out in the market. This has positioned us as one of the top leading companies not only in Egypt but also in the Middle East.",
    position: "CEO of KarmanPharma"
  },
  {
    id: uuidv4(),
    name: "Dr Ayman Elshaer",
    review: "Pharmachinary has exceeded our expectations with their exceptional services and commitment to innovation. Their support in optimizing our production facilities has been invaluable, allowing us to improve our operational efficiency and productivity. Their innovative ideas have been the driving force behind the creation of remarkable products, giving us a competitive edge in the market. We consider Pharmachinary as an integral part of our success, and their partnership has been a turning point in our journey towards becoming a leading pharmaceutical company in Egypt and the Middle East.",
    position: "Chairman of Medcare"
  },
  {
    id: uuidv4(),
    name: "DR Magdy Flemon",
    review: "We are delighted to have partnered with Pharmachinary, an outstanding company in the pharmaceutical industry. Their perfect services have streamlined our operations and enhanced our production capabilities. Beyond that, their focus on innovation has been truly inspiring. With their support, we have been able to create innovative products that have set us apart from our competitors. Pharmachinary's team, led by their strategic project lead, has been instrumental in providing valuable insights and solutions. Thanks to their collaboration, we have witnessed significant growth, gained a larger market share, and experienced a boost in profitability.",
    position: "Chairman of Pharmapack"
  },
  {
    id: uuidv4(),
    name: "Eng. Essam Saloma",
    review: "Pharmachinary has been an invaluable partner for our pharmaceutical business. Their innovative solutions in production facilities have helped us achieve our core objective of delivering groundbreaking products with a competitive edge. This partnership has played a pivotal role in establishing us as a leading company in both Egypt and the Middle East. Pharmachinary's commitment to excellence and continuous support have been commendable. They have not only provided perfect services but also fostered a culture of innovation within our organization. As we continue to grow, we are grateful to have Pharmachinary as our innovation partner, driving us forward on the path of success.",
    position: "Engineering Manager UBM"
  },
];


const Testimonials = () => {
  SwiperCore.use([Pagination, Autoplay]);

  return (
    <section className="testimonials pb-12">
      <div className="section-head-info flex flex-col items-center justify-center text-center p-8">
        <p className="text-red-600 font-bold py-1">Testimonials</p>
        <h1 className="text-2xl	font-bold dark py-1">
          What Our Clients Say About Us
        </h1>
      </div>
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        pagination
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        className="h-[400px] lg:h-[300px]"
      >
        {testimonialsList.map((testimonial) => (
            <SwiperSlide key={testimonial.id}>
              <Testimonial testimonial={testimonial} />
            </SwiperSlide>
        ))}

       </Swiper>
    </section>
  );
};

export default Testimonials;
