import React from 'react';

const GoogleMap = () => {
  return (
    <div className='lg:w-[50%]'><iframe title='googlemap' className='w-[100%]' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13812.593284688688!2d31.3557278!3d30.0612827!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x145817c3b48c74c1%3A0xb2de886b407c44b1!2sPharmachinery!5e0!3m2!1sen!2seg!4v1690291203634!5m2!1sen!2seg"  height="450" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe></div>
  )
}

export default GoogleMap;

